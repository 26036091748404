import {
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import * as React from "react";
import {useLayoutEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useLocales} from "../../../locales";
import {getPricePreviewByLineIdRateCardId} from "../../../api/trip";
import {fCurrency} from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import {getAllTicketsTypeByOperatorIdAPI} from "../../../api/ticketType/ticketType";
import PriceChangeDialog from "./PriceChangeDialog";
import {DIRECTIONS} from "../../Home/helper";
import {DIRECTION_NAME} from "../../../constants";

export default function PriceListDialog({data, onClose}) {
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();

    const [loading, setLoading] = useState(true);
    const [pricePreviewData, setPricePreviewData] = useState([]);
    const [discountData, setDiscountData] = useState([]);
    const [openDialog, setOpenDialog] = useState({
        open: false, data: null
    });

    const [refresh, setRefresh] = useState(false);
    useLayoutEffect(() => {
        const pricePreviewPromise = getPricePreviewByLineIdRateCardId(data);
        const ticketTypesPromise = getAllTicketsTypeByOperatorIdAPI(data?.tripId);

        Promise.all([pricePreviewPromise, ticketTypesPromise])
            .then(([pricePreviewResponse, ticketTypesResponse]) => {
                if(data?.arrStationId){
                    setPricePreviewData(pricePreviewResponse?.data?.filter(f => f.stationId === data?.arrStationId) || []);
                } else {
                    setPricePreviewData(pricePreviewResponse?.data || []);
                }
                setDiscountData(ticketTypesResponse?.data?.filter(f => f.operator !== null && f.active) || []);
            })
            .catch((error) => {
                console.error(error, "Error");
                enqueueSnackbar(translate("errorOccurred"), {variant: "error"});
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refresh]);

    const triggerRefresh = () => {
        setRefresh(prev => !prev);
    }
    const applyDiscounts = (price, row, key, index, direction) => {
        const {applyDiscountReturnTicket, applyDiscountOneWay, applyRoundPrice, id} = row;
        const foundException = pricePreviewData[index].priceExceptionsList.find(item => item.ticketTypeId == id)
        let adjustedPrice = price;
        let discountType = "NONE";
        if (foundException) {
            const exceptionPrice = foundException[key];
            if (exceptionPrice !== undefined && exceptionPrice !== null) {
                adjustedPrice = exceptionPrice;
                discountType = "EXCEPTION";
            } else {
                console.error("Exception price not found for key:", key, "in foundException:", foundException);
            }
        } else {
            if ((key === "priceOneWay" && applyDiscountOneWay) || (key === "returnTicketPrice" && applyDiscountReturnTicket)) {
                if(key === "priceOneWay"){
                    if (row.discountPCT > 0) {
                        adjustedPrice = price - (price * row.discountPCT / 100);
                        discountType = "PCT";
                    } else if (row.discountABS > 0) {
                        adjustedPrice = price - row.discountABS;
                        discountType = "ABS";
                    }
                } else {
                    if (row.discountPCTReturn > 0) {
                        adjustedPrice = price - (price * row.discountPCTReturn / 100);
                        discountType = "PCT";
                    } else if (row.discountABSReturn > 0) {
                        adjustedPrice = price - row.discountABSReturn;
                        discountType = "ABS";
                    }
                }
            }

            if (applyRoundPrice) {
                adjustedPrice = Math.ceil(adjustedPrice / 10) * 10;
            }

        }
        return {adjustedPrice, discountType};
    };


    const renderTableRows = () => {
        const rows = [
            {label: "JEDAN SMER", key: "priceOneWay"},
            {label: "POVRATNA CENA", key: "returnTicketPrice"},
        ];

        discountData.forEach((discount, i) => {
            // Provera za prikaz "JEDAN SMER"
            if (discount.applyDiscountOneWay) {
                rows.push({
                    label: `JEDAN SMER (${discount.name})`,
                    key: "priceOneWay",
                    discount: discount
                });
            }


            if (discount.applyDiscountReturnTicket) {
                rows.push({
                    label: `POVRATNA CENA (${discount.name})`,
                    key: "returnTicketPrice",
                    discount: discount
                });
            }

        });


        return rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
                <TableCell
                    sx={{
                        maxWidth: 200,
                        border: 1,
                        background: "#F4F6F8",
                        borderColor: 'divider',
                        fontSize: '0.775rem',
                        padding: '2px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                    }}>
                    {row.label}
                </TableCell>
                {pricePreviewData.map((item, index) => (
                    <TableCell
                        key={index}
                        align="center"
                        sx={{border: 1, borderColor: 'divider', fontSize: '0.875rem', padding: '4px'}}
                    >
                        <Stack direction="row" justifyContent="space-between">
                            {row.discount
                                ? fCurrency(`${applyDiscounts(item[row.key], row.discount, row.key, index).adjustedPrice}`)
                                : fCurrency(`${item[row.key]}`)
                            }
                            {row.discount &&
                                <IconButton
                                    disabled={!data.editable}
                                    onClick={() => {
                                        const {key} = row
                                        const {stationId, id} = pricePreviewData[index]
                                        const priceOneWay = applyDiscounts(item["priceOneWay"], row.discount, "priceOneWay", index).adjustedPrice
                                        const priceReturnTicket = applyDiscounts(item["returnTicketPrice"], row.discount, "returnTicketPrice", index).adjustedPrice
                                        const priceMonthly = applyDiscounts(item["priceMonthly"], row.discount, "priceMonthly", index).adjustedPrice

                                        const OBJ = {
                                            id: id,
                                            rateCardId: data.rateCardId,
                                            station1Id: 1645,
                                            station2Id: stationId,
                                            ticketTypeId: row?.discount?.id || null,
                                            priceOneWay,
                                            priceReturnTicket,
                                            priceMonthly
                                        }
                                        setOpenDialog({
                                            open: true,
                                            data: {...OBJ, key: key},
                                        })
                                    }}
                                    size="small"
                                    sx={{marginLeft: 1}}
                                >
                                    <Iconify width={15} icon="mdi:pencil"/>
                                </IconButton>
                            }
                        </Stack>

                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    return (
        <Dialog fullWidth maxWidth="xl" open={data.open} onClose={onClose}>
            <DialogContent>
                {loading ? "Učitavanje podataka..." : (
                    <div>
                        <Stack
                            direction="row"
                            spacing={1.2}
                            justifyContent="center"
                            alignItems="center"
                            width={1}
                            mb={2}
                            sx={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white'}}
                        >
                            <Typography variant="subtitle1" fontSize="0.875rem">Linija: {data?.lineName}</Typography>
                            <Typography variant="subtitle1"
                                        fontSize="0.875rem">Prevoznik: {data?.operatorName}</Typography>
                        </Stack>
                        <TableContainer component={Paper} sx={{maxHeight: '80vh', overflow: 'auto'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{
                                            border: 1,
                                            borderColor: 'divider',
                                            fontSize: '0.875rem',
                                            padding: '4px',
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'white',
                                            zIndex: 2
                                        }}></TableCell>
                                        {pricePreviewData.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                align="center"
                                                sx={{
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    fontSize: '0.875rem',
                                                    padding: '4px',
                                                    position: 'sticky',
                                                    top: 0,
                                                    zIndex: 2
                                                }}
                                            >
                                                <Typography variant="subtitle2"
                                                            fontSize="0.875rem">{item.stationName}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {renderTableRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </DialogContent>
            {openDialog.open && <PriceChangeDialog
                setLoading={setLoading}
                open={openDialog.open}
                data={openDialog.data}
                handleClose={() => {
                    setOpenDialog({open: false, data: null})
                    triggerRefresh()
                }}/>}
        </Dialog>
    );

}
