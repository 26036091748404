import {Box, Dialog, DialogContent, Divider, Table, TableBody, TableContainer, Typography} from '@mui/material';
import moment from "moment/moment";
import {useEffect, useState} from "react";
import {searchTripReturnApi} from "../../api/trip";
import {useLocales} from "../../locales";
import {TableHeadCustom, TableNoData, TablePaginationCustom, TableSkeleton, useTable} from "../../components/table";
import {useFormContext} from "react-hook-form";
import Scrollbar from "../../components/Scrollbar";
import DepartureFromSpotToolbar from "./DepartureFromSpotToolbar";
import DepartureFromSpotTableRow from "./DepartureFromSpotTableRow";
import {useSelector} from "../../redux/store";

const TABLE_HEAD = translate => [
    {id: "name", label: "Linija", align: "left"},
    {id: "operator", label: "Prevoznik", align: "left"},
    {id: "departureTime", label: "Dolazak u NS", align: "left"},
    {id: "departureTime", label: "Polazak iz mesta", align: "left"},
    {id: "comment", label: "Komentar", align: "left"},
    {id: "", width: 10, sortable: false}
];

export default function InfoDialog({data, open, onClose}) {
    const {translate} = useLocales();
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage
    } = useTable({
        defaultOrderBy: "id"
    });

    const {homeArrStations} = useSelector(state => state.stations);

    const {getValues} = useFormContext();
    const [openRowId, setOpenRowId] = useState(null);

    const toLocationId = getValues('toLocationId')
    const [foundResult, setFoundResult] = useState([])
    const [endStation, setEndStation] = useState(data?.arrStationId)
    const [filterLineName, setFilterLineName] = useState("");
    const [filterOperatorName, setFilterOperatorName] = useState(data?.operator || "");
    const [filterDate, setFilterDate] = useState(moment(data?.dateFrom).format("YYYY-MM-DD HH:mm:ss") || new Date());

    const [loadingLines, setLoadingLines] = useState(false);

    const isFiltered = filterLineName !== "" ||
        filterOperatorName !== ""

    const filteredData = applyFilter({
        inputData: foundResult,
        filterLineName: filterLineName,
        filterOperatorName: filterOperatorName
    })

    useEffect(() => {
        if (endStation) {
            setLoadingLines(true)
            searchTripReturnApi({
                departureStationId: endStation,
                arrivalStationId: data?.depStationId,
                from: moment(filterDate).format("YYYY-MM-DD HH:mm:ss"),
                operatorId: data.operatorId || null,
                toLocationId: null,
            }).then(({data}) => {
                setFoundResult(data)
            }).catch(e => {
                console.error(e, "EEE")
            }).finally(() => {
                setLoadingLines(false)
            })
        }

    }, [filterDate, endStation]);

    const handleFilterLineName = (event) => {
        setPage(0);
        setFilterLineName(event.target.value);
    };

    const handleFilterOperatorName = (event) => {
        setPage(0);
        setFilterOperatorName(event.target.value);
    };

    const handleResetFilter = () => {
        setFilterLineName("");
        setFilterOperatorName("");
        setFilterDate(null);
    };

    return (
        <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
            <DialogContent sx={{pb: 2}}>
                <Box mb={3}>
                    <Typography
                        variant="h4"
                        sx={{fontWeight: 'bold', mb: 2}}
                    >
                        Povratak iz mesta
                    </Typography>
                    <Divider/>
                </Box>
                <DepartureFromSpotToolbar
                    isIndividual={data?.isIndividual}
                    homeArrStations={homeArrStations?.filter(f => f.locationId === toLocationId)}
                    setEndStation={setEndStation}
                    endStation={endStation}
                    operator={data?.operator}
                    isFiltered={isFiltered}
                    filterLineName={filterLineName}
                    filterOperatorName={filterOperatorName}
                    filterDate={filterDate}
                    onFilterDate={(newValue) => {
                        setFilterDate(newValue)
                    }}
                    onFilterLineName={handleFilterLineName}
                    onFilterOperatorName={handleFilterOperatorName}
                    onResetFilter={handleResetFilter}
                />

                <TableContainer sx={{position: "relative", overflow: "unset"}}>
                    <Scrollbar>
                        <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD(translate)}
                                rowCount={foundResult?.length || 0}
                                onSort={onSort}
                            />


                            <TableBody>
                                {loadingLines ?
                                    Array.from({length: rowsPerPage}, (_, i) => (
                                        <TableSkeleton key={i} columns={TABLE_HEAD(translate).length}/>
                                    ))
                                    :
                                    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) =>
                                        <DepartureFromSpotTableRow
                                            key={index}
                                            index={index}
                                            openRowId={openRowId}
                                            setOpenRowId={setOpenRowId}
                                            row={row}
                                        />)
                                }
                                <TableNoData isNotFound={!loadingLines && filteredData.length === 0}/>
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    loading={loadingLines}
                    count={filteredData?.length || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    //
                    dense={dense}
                    onChangeDense={onChangeDense}
                />
            </DialogContent>
        </Dialog>
    );
}

function applyFilter({inputData, filterLineName, filterOperatorName}) {

    if (filterLineName) {
        inputData = inputData.filter((product) => product.lineName.toLowerCase().indexOf(filterLineName.toLowerCase()) !== -1);
    }

    if (filterOperatorName) {
        inputData = inputData.filter((product) => product.operatorName.toLowerCase().indexOf(filterOperatorName.toLowerCase()) !== -1);
    }

    return inputData;
}
