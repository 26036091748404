import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { useLocales } from "../../../locales";
import Iconify from "../../../components/Iconify";
import { RHFCheckbox, RHFTextField } from "../../../components/hook-form";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const additionalFields = [
    { name: "Firstname", value: "firstName", type: "text" },
    { name: "Lastname", value: "lastName", type: "text" },
    { name: "Identification number", value: "identificationNumber", type: "text" },
    { name: "Identification expiry date", value: "identificationExpiryDate", type: "text" },
    { name: "Birthdate", value: "birthdate", type: "text" },
    {
        name: "Gender",
        value: "gender",
        type: "select",
        options: [{ label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "x" }]
    },
    {
        name: "Type",
        value: "type",
        type: "select",
        options: [{ label: "Adult", value: "adult" }, { label: "Child", value: "child" }]
    },
    {
        name: "Identification type",
        value: "identificationType",
        type: "select",
        options: [{ label: "International passport", value: "international_passport" }]
    },
    {
        name: "Identification issuing country",
        value: "identificationIssuingCountry",
        type: "select",
        options: [
            { label: "Serbia", value: "RS" },
            { label: "Croatia", value: "HR" },
            { label: "Bosnia and Herzegovina", value: "BA" },
            { label: "Slovakia", value: "SK" },
            { label: "Hungary", value: "HU" },
            { label: "Russia", value: "RU" },
            { label: "Macedonia", value: "MK" }
        ]
    },
    {
        name: "Citizenship",
        value: "citizenship",
        type: "select",
        options: [
            { label: "Serbia", value: "RS" },
            { label: "Croatia", value: "HR" },
            { label: "Bosnia and Herzegovina", value: "BA" },
            { label: "Slovakia", value: "SK" },
            { label: "Hungary", value: "HU" },
            { label: "Russia", value: "RU" },
            { label: "Macedonia", value: "MK" }
        ]
    },
    {
        name: "Visa permit type",
        value: "visaPermitType",
        type: "select",
        options: [
            { label: "Single or double entry visa", value: "single_or_double_entry_visa" },
            { label: "Multiple entry visa", value: "multiple_entry_visa" },
            { label: "EU citizen", value: "eu_cy_ie_citizen" },
            { label: "EU family and residence card", value: "eu_family_and_residence_card" },
            { label: "NON EU family and residence card", value: "non_eu_family_and_residency" },
            { label: "EU residence", value: "eu_residence" },
            { label: "NON EU mobility for work or study", value: "non_eu_mobility_for_work_or_study" },
            { label: "Long stay visa", value: "long_stay_visa" },
            { label: "Microstate or Vatican national", value: "microstate_or_vatican_national" },
            { label: "Border check exempt", value: "border_check_exempt" },
            { label: "Special border check category", value: "special_border_check_category" }
        ]
    },
    {
        name: "Visa data provided",
        value: "visaDataProvided",
        type: "select",
        options: [{ label: "EES Schengen", value: "ees_schengen" }]
    }
];

export default function PassengerDetails({ isFlix, index, showRows }) {
    const { translate } = useLocales();
    const [open, setOpen] = useState(false);

    const { control } = useFormContext();

    const passengers = useWatch({
        control,
        name: "passengers"
    });

    const phone = passengers[index]?.phone;

    return (
        <Accordion open={open} onClose={() => setOpen(true)}>
            <AccordionSummary expandIcon={<Iconify icon="ep:arrow-down-bold" />}>
                <Typography>{translate("moreDetails")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    gap={2}
                    display="grid"
                    gridTemplateColumns={{
                        xs: `repeat(1, 1fr)`,
                        sm: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                        md: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                        lg: `repeat(${showRows ? "1" : "2"}, 1fr)`,
                    }}
                >
                    {!isFlix ? (
                        <>
                            <RHFTextField
                                size="small"
                                name={`passengers.${index}.name`}
                                label="Ime"
                                InputLabelProps={{ shrink: true }}
                            />
                            <RHFTextField
                                size="small"
                                name={`passengers.${index}.surname`}
                                label="Prezime"
                                InputLabelProps={{ shrink: true }}
                            />
                            <RHFTextField
                                size="small"
                                name={`passengers.${index}.phone`}
                                label="Telefon"
                                InputLabelProps={{ shrink: true }}
                            />
                            <RHFTextField
                                size="small"
                                name={`passengers.${index}.email`}
                                label="Mejl"
                                InputLabelProps={{ shrink: true }}
                            />
                        </>
                    ) : (
                        additionalFields.map((field) => (
                            field.type === "select" ? (
                                <RHFTextField
                                    key={field.value}
                                    select
                                    size="small"
                                    name={`passengers.${index}.${field.value}`}
                                    label={field.name}
                                >
                                    {field.options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </RHFTextField>
                            ) : (
                                <RHFTextField
                                    key={field.value}
                                    size="small"
                                    name={`passengers.${index}.${field.value}`}
                                    label={field.name}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )
                        ))
                    )}
                    <Stack direction={{ md: 'row', sm: 'column' }} spacing={1.2}>
                        <Tooltip title="Poruka sa detaljima kupovine karte će biti prosleđena korisniku na telefon">
                            <div>
                                <RHFCheckbox
                                    disabled={phone?.length < 7}
                                    name={`passengers.${index}.sms`}
                                    label="Prosledi poruku" />
                            </div>
                        </Tooltip>
                        <Tooltip title="Putno osiguranje ukoliko postoji sklopljen ugovor sa osiguravajućom kućom">
                            <div>
                                <RHFCheckbox
                                    disabled
                                    name={`passengers.${index}.insurance`}
                                    label="Putno osiguranje" />
                            </div>
                        </Tooltip>
                    </Stack>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
