import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableContainer,
    Tabs,
    TextField
} from "@mui/material";
import PrivilegesToolbar from "../../Privileges/PrivilegesToolbar";
import * as React from "react";
import {useEffect, useState} from "react";
import {fetchAllRFIDCard, insertNewRFIDCardAction} from "../../../redux/slices/rfid";
import {TableHeadCustom, TableNoData, TablePaginationCustom, TableSkeleton, useTable} from "../../../components/table";
import {useSnackbar} from "notistack";
import {useLocales} from "../../../locales";
import Scrollbar from "../../../components/Scrollbar";
import {dispatch, useSelector} from "../../../redux/store";
import PrivilegesDialogTableRow from "./PrivilegesDialogTableRow";
import {DatePicker} from "@mui/x-date-pickers";
import {CalendarIconSvg} from "../../../constants/icons";
import {fetchAllDisabilities} from "../../../redux/slices/disability";
import moment from "moment";
import {useFormContext} from "react-hook-form";
import {nextDay} from "../../../utils/DateFormater";

const default_filter = {
    clientName: "",
    clientSurname: "",
    cardNumber: "",
    clientIdentity: "",
    toStationName: "",
    fromStationName: "",
    lineName: "",
    operatorId: null,
    disabilityId: null,
    validTo: undefined,
    validFrom: undefined,
};

export default function PrivilegeDialog({sales, open, onClose, passengerIndex, onSubmit}) {

    const {setValue, getValues} = useFormContext();
    const passengersState = getValues("passengers")
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const [currentTab, setCurrentTab] = useState(0);
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        selectedOne,
        onSelectOneRow,
    } = useTable({
        defaultOrderBy: "cardNumber"
    });

    const {disabilitiesList, loadingDisabilities} = useSelector((state => state.disability));
    const {foundRFIDCards, loadingRFIDCards} = useSelector((state => state.RFID));
    const [filter, setFilter] = useState(default_filter);
    const [formData, setFormData] = useState({
        clientName: "",
        clientSurname: "",
        clientIdentity: "",
        placeOfIssue: "",
        disabilityId: "",
        cardNumber: "",
        validFrom: null,
        validTo: null
    });

    useEffect(() => {
        dispatch(fetchAllDisabilities({page: 0, rowsPerPage: 50}));
    }, []);

    useEffect(() => {
        fetchData(filter, {
            rowsPerPage,
            page,
            order,
            orderBy
        });
    }, [order, orderBy, page, rowsPerPage]);

    const fetchData = (filter, params) => {
        dispatch(fetchAllRFIDCard({
            filter: {
                ...filter,
                operatorId: filter.operatorId?.id || null,
                disabilityId: filter.disabilityId?.id || null,
            },
            params
        })).unwrap().catch(() => {
            enqueueSnackbar(translate("errorFetching"), {variant: "error"})
        });
    };

    const isFormDataValid = () => {
        const requiredFields = ['clientName', 'clientSurname', 'clientIdentity', 'disabilityId', 'validFrom', 'validTo'];
        for (const field of requiredFields) {
            if (!formData[field]) {
                return false;
            }
        }
        return true;
    };

    const handleFilterChange = (event) => {
        let {name, value} = event.target;
        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSearch = () => {
        setPage(0);
        fetchData(filter, {
            page,
            rowsPerPage,
            order,
            orderBy,
        });
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter(default_filter);
        fetchData(default_filter, {
            page: 0,
            rowsPerPage,
            order,
            orderBy,
        });
    };

    const findTicketType = (id) => {
        if (sales) {
            return sales.find(sale => sale.ticketTypeId === id)
        }
        return null
    }
    const handleFormSubmit = () => {
        if (currentTab === 0) {
            const founded = foundRFIDCards?.content?.find(item => item.cardNumber === selectedOne);
            const foundSale = findTicketType(founded.ticketTypeId);
            const parsedFounded = {...founded, ticketType: foundSale}
            const {ticketTypeId, explicit, priceOneWay, priceReturnTicket, priceMonthly,
                documentValidation,applyDiscountOneWay,
                applyDiscountReturnTicket, applyToAllTrips,
                applyRoundPrice, onlineSale, name} = parsedFounded.ticketType;

            const PASSENGER_OBJ = {
                havePrivilege: true,
                discountPCT: {
                    ticketTypeId,
                    explicit,
                    priceOneWay,
                    priceReturnTicket,
                    priceMonthly,
                    documentValidation,
                    applyDiscountOneWay,
                    applyDiscountReturnTicket,
                    applyToAllTrips,
                    applyRoundPrice,
                    onlineSale,
                    name,
                },
                type: "adult",
                email: parsedFounded?.email || "",
                insurance: false,
                name: parsedFounded?.clientName || "",
                phone: parsedFounded?.phone || "",
                sms: parsedFounded?.sms || "",
                surname: parsedFounded?.clientSurname || "",
                clientIdentity: parsedFounded?.clientIdentity || "",
            };

            if (PASSENGER_OBJ) {
                setValue(`passengers.${passengerIndex}`, PASSENGER_OBJ);
                onSubmit();
            }
        } else {
            if (isFormDataValid()) {
                const foundSale = findTicketType(formData.disabilityId);
                const subTicketType = subDisabilityOptions()?.find(f => f.id === formData?.subTicketTypeId)
                const OBJ = {
                    ...formData,
                    ticketType: {
                        id: foundSale.ticketTypeId
                    },
                    subTicketTypeId: subTicketType?.id || null,
                    validFrom: moment(formData.validFrom).format("YYYY-MM-DD"),
                    validTo: moment(formData.validTo).format("YYYY-MM-DD")
                };
                const PASSENGER_OBJ = {
                    havePrivilege: true,
                    discountPCT: {
                        ticketTypeId: foundSale.ticketTypeId,
                        explicit: foundSale.explicit,
                        priceOneWay: foundSale.priceOneWay,
                        priceReturnTicket: foundSale.priceReturnTicket,
                        priceMonthly: foundSale.priceMonthly,
                        documentValidation: foundSale.documentValidation,
                        applyDiscountOneWay: foundSale.applyDiscountOneWay,
                        applyDiscountReturnTicket: foundSale.applyDiscountReturnTicket,
                        applyToAllTrips: foundSale.applyToAllTrips,
                        applyRoundPrice: foundSale.applyRoundPrice,
                        onlineSale: foundSale.onlineSale,
                        name: foundSale.name,
                    },
                    type: "adult",
                    email: formData?.email || "",
                    insurance: false,
                    name: formData?.clientName || "",
                    phone: formData?.phone || "",
                    sms: formData?.sms || "",
                    surname: formData?.clientSurname || "",
                    clientIdentity: formData?.clientIdentity || "",
                };
                dispatch(insertNewRFIDCardAction(OBJ)).unwrap().then(_ => {
                    setValue(`passengers.${passengerIndex}`, PASSENGER_OBJ);
                    onSubmit();
                }).catch(e => {
                    console.error(e);
                    enqueueSnackbar(translate("errorOnEdit"), {variant: 'error'});
                });
            } else {
                enqueueSnackbar(translate("fillOutAllFields"), {variant: 'warning'});
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleFormChange = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateChange = (name, date) => {
        setFormData({
            ...formData,
            [name]: date
        });
    };

    const subDisabilityOptions = () => {
        if (disabilitiesList?.content) {
            const found = disabilitiesList?.content.find(d => d.id === formData?.disabilityId)?.subTicketTypes;
            return found?.length > 0 ? found : undefined;
        }
        return undefined
    }

    return (
        <Dialog fullWidth={true} maxWidth="xl" open={open} onClose={onClose}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleFormSubmit();
                    }
                }}>
            <DialogTitle sx={{textAlign: 'center'}}>Povlastice</DialogTitle>

            <Box sx={{px: 4}}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab label="Lista korisnika"/>
                    <Tab label="Nov korisnik"/>
                </Tabs>
            </Box>

            <DialogContent>
                {currentTab === 0 && (
                    <Card>
                        <PrivilegesToolbar
                            filter={filter}
                            onChange={handleFilterChange}
                            onSearch={handleSearch}
                            onReset={handleResetFilter}
                        />

                        <TableContainer sx={{position: "relative", overflow: "unset"}}>
                            <Scrollbar>
                                <Table size={dense ? "small" : "medium"} sx={{minWidth: 960}}>
                                    <TableHeadCustom
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={[
                                            {id: "", label: "", align: "left"},
                                            {id: "clientName", label: translate("name"), align: "left"},
                                            {id: "clientSurname", label: translate("surname"), align: "left"},
                                            {id: "clientIdentity", label: "Broj lk.", align: "left"},
                                            {id: "cardNumber", label: "Broj povlastice", align: "left"},
                                            {id: "privilegeType", label: "Vrsta povlastice", align: "left"},
                                            {id: "validFrom", label: "Datum izdavanja", align: "left"},
                                            {id: "validTo", label: "Rok važenja", align: "left"}
                                        ]}
                                        rowCount={foundRFIDCards?.content?.length}
                                        onSort={onSort}
                                    />

                                    <TableBody>
                                        {loadingRFIDCards ?
                                            Array.from({length: rowsPerPage}, (_, i) => (
                                                <TableSkeleton key={i} columns={8}/>
                                            ))
                                            :
                                            foundRFIDCards?.content?.map((row) =>
                                                <PrivilegesDialogTableRow
                                                    key={row.cardNumber}
                                                    row={row}
                                                    prevSelectedPassenger={passengersState}
                                                    selected={selectedOne === row.cardNumber}
                                                    onSelectRow={() => onSelectOneRow(row.cardNumber)}
                                                />)
                                        }
                                    </TableBody>
                                    <TableNoData isNotFound={!loadingRFIDCards && foundRFIDCards.totalElements === 0}/>
                                </Table>
                            </Scrollbar>
                        </TableContainer>

                        <TablePaginationCustom
                            count={foundRFIDCards?.totalElements || 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            dense={dense}
                            onChangeDense={onChangeDense}
                        />
                    </Card>
                )}
                {currentTab === 1 && (
                    <Card>
                        <Stack direction={{md: 'row', sm: 'column'}} sx={{pl: 1, pr: 1}} minWidth={200} spacing={2}
                               width={"100%"}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid lightGrey',
                                padding: 2,
                                borderRadius: '5px',
                                gap: 2,
                                width: "100%"
                            }}>
                                <TextField size="small" name="clientName" label="Ime*" value={formData.clientName}
                                           onChange={handleFormChange}/>
                                <TextField size="small" name="clientSurname" label="Prezime*"
                                           value={formData.clientSurname} onChange={handleFormChange}/>
                                <TextField size="small" name="clientIdentity" label="Broj lk."
                                           value={formData.clientIdentity} onChange={handleFormChange}/>
                                <TextField size="small" name="placeOfIssue" label={translate("issuePlace")}
                                           value={formData.placeOfIssue} onChange={handleFormChange}/>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid lightGrey',
                                padding: 2,
                                borderRadius: '5px',
                                gap: 2,
                                width: "100%"
                            }}>
                                <Stack direction="row" spacing={1.2}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>{translate("invalid")}</InputLabel>
                                        <Select name="disabilityId" value={formData.disabilityId}
                                                onChange={handleFormChange}
                                                input={<OutlinedInput label={translate("invalid")}/>}>
                                            {disabilitiesList?.content?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {subDisabilityOptions() && <FormControl fullWidth size="small">
                                        <InputLabel>{translate("subPrivilege")}</InputLabel>
                                        <Select name="subTicketTypeId" value={formData.subTicketTypeId}
                                                onChange={handleFormChange}
                                                input={<OutlinedInput label={translate("subPrivilege")}/>}>
                                            {subDisabilityOptions()?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>}
                                </Stack>

                                <TextField size="small" name="cardNumber" label={translate("privilegePass")}
                                           value={formData.cardNumber} onChange={handleFormChange}/>
                                <Stack direction="row" spacing={1.2}>
                                    <DatePicker
                                        value={formData.validFrom}
                                        onChange={(date) => {
                                            handleDateChange("validFrom", date)
                                            // handleDateChange("validTo", nextDayIfDateToIsBefore(date, formData.validTo))
                                        }}
                                        label={translate("issueDate")}
                                        slotProps={{textField: {size: 'small'}}}
                                        slots={{openPickerIcon: CalendarIconSvg}}
                                    />
                                    <DatePicker
                                        minDate={new Date(formData?.validFrom) || nextDay()}
                                        value={formData.validTo}
                                        onChange={(date) => handleDateChange("validTo", date)}
                                        label={translate("durationTime")}
                                        slotProps={{textField: {size: 'small'}}}
                                        slots={{openPickerIcon: CalendarIconSvg}}
                                    />
                                </Stack>
                            </Box>
                        </Stack>
                    </Card>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Odustani
                </Button>
                <Button type="submit" variant="contained" color="success" onClick={handleFormSubmit}
                        disabled={currentTab === 0 && selectedOne === null}>
                    Primeni {currentTab === 1 ? "i sačuvaj" : ""}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
