import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {searchTripApi} from "../../api/trip";
import {dispatch, useSelector} from "../../redux/store";
import LoadingScreen from "../../components/loading-screen";
import {useSnackbar} from "notistack";
import {useLocales} from "../../locales";
import DepartureCard from "./DepartureCard";
import {setDefaultUserSettings} from "../../redux/slices/userSettings";
import {Stack, Typography} from "@mui/material";
import {getSessionSelectedLocation, removeSessionOperator, setSessionSelectedLocation} from "../../helper/session";
import {PATH_AUTH} from "../../routes/path";
import {useNavigate} from "react-router-dom";
import {fetchAllLocations} from "../../redux/slices/locations";
import LocationDialog from "../../components/LocationDialog";
import EmptyContent from "../../components/empty-content";
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {getStationScheduler} from "../../api/currentTripSchedule";

const MAX_ROWS = 6;
const MIN_ROWS = 15;

export default function DeparturePreview() {
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [trips, setTrips] = useState([]);
    const [page, setPage] = useState(1);
    const [fetchingMore, setFetchingMore] = useState(null);
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(dayjs());

    const timerRef = useRef(null);

    const {locations, loading: loadingLocation, error} = useSelector(select => select.locations);
    const {defaultDepStations, loading: loadingUserSettings, services} = useSelector(state => state.userSettings);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(dayjs());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setPage(prevState => prevState === 2 ? 1 : 2)
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTrips(prevState => prevState.filter(trip =>
                moment().isBefore(trip.fromStationDepartureDt)))
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (trips.length === 0 && defaultDepStations?.[0]) {
            fetchInitData(defaultDepStations?.[0]);
        }
    }, [defaultDepStations]);

    useEffect(() => {
        dispatch(setDefaultUserSettings());
    }, []);

    useEffect(() => {
        if (!fetchingMore && !loading) {
            if (trips.length < MIN_ROWS) {
                fetchMoreData(defaultDepStations?.[0])
            }
        }
    }, [trips.length]);

    useLayoutEffect(() => {
        dispatch(fetchAllLocations()).unwrap().then(response => {
            if (response.length === 1) {
                setSessionSelectedLocation(response[0]);
            } else {
                if (getSessionSelectedLocation() === undefined) {
                    setOpenLocationDialog(true);
                } else {
                    let selected = response.some(location => location.PAC === getSessionSelectedLocation().PAC);
                    if (!selected) {
                        setOpenLocationDialog(true);
                    }
                }
            }
        }).catch(reason => {
            console.error("fetchAllLocations", reason);
            enqueueSnackbar("Nije moguće očitati lokacije", {variant: "error"});
        });
        // eslint-disable-next-line
    }, []);

    const fetchInitData = (depId) => {
        if (!depId || loading) {
            return;
        }
        setLoading(true);
        getStationScheduler(depId).then(({data}) => {
            setTrips(data);
        }).catch(reason => {
            console.error("searchTripApi", reason);
            enqueueSnackbar(translate("errorFetching"), {variant: "error"});
        }).finally(() => {
            setLoading(false);
        });
    };

    const fetchMoreData = (depId) => {
        if (!depId || loading) {
            return;
        }
        setFetchingMore(true)
        getStationScheduler(depId).then(({data}) => {
            const arr = [...data];
            setTrips([...arr, ...data]);
        }).catch(reason => {
            console.error("fetchMoreData", reason);
        }).finally(() => {
            setFetchingMore(false)
        })
    }

    const handleLogout = (event) => {
        if (event.detail > 3) {
            try {
                removeSessionOperator();
                clearInterval(timerRef.current);
                navigate(PATH_AUTH.login, {replace: true});
            } catch (error) {
                console.error(error);
                enqueueSnackbar('Došlo je do greške!', {variant: 'error'});
            }
        }
    };

    const onLocationChosen = (e, location) => {
        setSessionSelectedLocation(location);
        setOpenLocationDialog(false);
        enqueueSnackbar("Uspešno dodeljena lokacija", {variant: "success"});
    };

    if (loading || loadingUserSettings) {
        return <LoadingScreen/>;
    }

    const filteredData = trips?.filter(c => c.canceled !== true)
        .slice(page === 1 ? 0 : MAX_ROWS, page === 1 ? MAX_ROWS : (MAX_ROWS * 2))

    const issuingItemsPrice = services.filter(item => item.includeOnIssuingTicket)
        .reduce((acc, item) => acc + item.price, 0)

    return (
        <Stack sx={{p: 1}}>
            <Typography
                sx={{
                    userSelect: 'none',
                    cursor: 'pointer',
                }}
                onClick={handleLogout}
                variant="h3"
                textAlign="center">
                Polasci u narednom periodu
                za {`${currentDateTime.format('DD.MM.YYYY')}
                vreme: ${currentDateTime.format('HH:mm:ss')} (${page}/2)`}
            </Typography>
            {filteredData.map((trip, index) =>
                <DepartureCard
                    startStation={defaultDepStations[0]}
                    index={index}
                    key={`${index} - ${trip.tripId}`}
                    issuingItemsPrice={issuingItemsPrice}
                    trip={trip}/>
            )}
            {(trips?.length === 0 && !loading) &&
                <EmptyContent
                    title={translate("noTripsOnDate")}
                    sx={{
                        '& span.MuiBox-root': {height: 160},
                    }}
                />
            }
            {openLocationDialog && <LocationDialog
                locations={locations}
                loading={loadingLocation}
                open={openLocationDialog}
                error={error}
                handleLocationClick={onLocationChosen}/>}
        </Stack>
    );
}
