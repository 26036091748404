import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider
} from "@mui/material";
import moment from 'moment';

export default function ItinererDialog({data, open, onClose}) {

    const calculateTime = (baseTime, addTime) => {
        const [baseHours, baseMinutes, baseSeconds] = baseTime.split(':').map(Number);
        const [addHours, addMinutes, addSeconds] = addTime.split(':').map(Number);

        let totalSeconds = baseSeconds + addSeconds;
        let totalMinutes = baseMinutes + addMinutes + Math.floor(totalSeconds / 60);
        let totalHours = baseHours + addHours + Math.floor(totalMinutes / 60);

        totalSeconds %= 60;
        totalMinutes %= 60;
        totalHours %= 24;

        return `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <List>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <Typography variant="h6" sx={{width: 300}}>
                                            {item.station.name}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" color="textSecondary">
                                            Dolazak: {calculateTime(item.tripDepartureTime, item.arrivalTime)}
                                        </Typography>
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" color="textSecondary">
                                            Polazak: {calculateTime(item.tripDepartureTime, item.departureTime)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            {index < data.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Zatvori
                </Button>
            </DialogActions>
        </Dialog>
    );
}
