import {customAxiosBE} from "../config";
import axios from "axios";
import {parsePaginationParams} from "../../utils/other";

export async function getStationByIdApi(id) {
    return customAxiosBE.get(`/station/${id}`)
}

export async function getAllStationsApi(operatorUid) {
    let query = ""
    if (operatorUid) {
        query = "?operatorUid=" + operatorUid
    }
    return customAxiosBE.get(`/station${query}`)
}

export async function getAllDefaultDepStationsApi(depStationsIds) {
    if(depStationsIds?.length > 0){
        return customAxiosBE.post(`/station/defaultDepStations`, depStationsIds)
    }
}

export async function getAllArrivalStationsByDepStationId(id) {
    return customAxiosBE.get(`/station/arrival/${id}`)
}

export async function getAllStationsByLineId(id) {
    return customAxiosBE.get(`/station/line/${id}`)
}

export function insertStationAPI(data) {
    return customAxiosBE.post(`/station`, data)
}

export function updateStationAPI(data) {
    return customAxiosBE.put(`/station`, data)
}

export function deleteStationAPI(id) {
    return customAxiosBE.delete(`/station/${id}`)
}


export const searchStationAPI = async (data, cancelToken) => {
    let response
    const {name} = data
    const parsedParams = parsePaginationParams(data)
    await customAxiosBE
        .post(`/station/search?${parsedParams}`, {
                name: name,
                onlyOfficial:  true
            },
            {
                cancelToken
            })
        .then((r) => {
            console.log(r.data, "DATAA")
            response = r.data
        })
        .catch(error => {
            console.error('usao u err')
            if (axios.isCancel(error)) {
                console.error('Request canceled', error.message);
            } else {
                // handle error response
            }
        });
    return response
}
