import axios from "axios";
import { AUTH } from "../auth/FirebaseContext";
import { BUS_SOFT_API, BUS_SOFT_API_LOCAL, BUS_SOFT_API_TEST } from "../config";
import {getSessionOperator} from "../helper/session";
import {convertItemName} from "../helper/other";

let baseURL;

if (process.env.NODE_ENV === "production") {
    const hostname = window.location.hostname;
    if (hostname === "app.bussoft.rs") {
        baseURL = BUS_SOFT_API;
    } else {
        baseURL = BUS_SOFT_API_TEST;
    }
} else {
    baseURL = BUS_SOFT_API_LOCAL;
}

export const customAxiosBE = axios.create({
    baseURL: baseURL,
    headers: {
        'client_os': 'web',
        'client_app': 'busSoft-new',
        'client_version': '1',
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US'
    },
    withCredentials: false,
});

let tokenCache = {
    value: null,
    expirationTime: null,
};

export const resetTokenCache = () => {
    tokenCache.value = null;
    tokenCache.expirationTime = null;
};

const getToken = async () => {
    const currentUser = AUTH.currentUser;
    if (!currentUser) {
        return null;
    }

    if (tokenCache.value && tokenCache.expirationTime > Date.now()) {
        return tokenCache.value;
    }

    const idTokenResult = await currentUser.getIdTokenResult();
    tokenCache.value = idTokenResult.token;
    tokenCache.expirationTime = new Date(idTokenResult.expirationTime).getTime();

    return tokenCache.value;
};

customAxiosBE.interceptors.request.use(
    async (config) => {
        const token = await getToken();
        const operator = await getSessionOperator();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            if(operator){
                config.headers.operator_uid  = operator.uid;
                config.headers.operator_name = convertItemName(operator.username);
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

customAxiosBE.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            resetTokenCache();
            const token = await getToken();
            if (token) {
                customAxiosBE.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
            return customAxiosBE(originalRequest);
        }
        return Promise.reject(error.response || "Something went wrong");
    },
);
