import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {FirebaseContext} from "../../context/FirebaseMessages";

const NotificationDialog = () => {
    const {newMessage, markMessageAsRead} = useContext(FirebaseContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (newMessage) {
            setOpen(true);
        }
    }, [newMessage]);

    const handleClose = () => {
        if (newMessage) {
            markMessageAsRead(newMessage.id);
        }
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{newMessage?.title || "Novo obaveštenje sa kioska"}</DialogTitle>
            <DialogContent>
                <p>{newMessage?.body || "Imate novo obaveštenje!"}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zatvori</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationDialog;
