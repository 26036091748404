import {DIRECTION_NAME} from "../../constants";
import uuidv4 from "../../utils/uuidv4";


export const handleBusTicketItem = (state, isTaxFree, directionType, passengers, returnTrip) => {
    const TICKET_ITEMS_OBJ = [];

    function addOrUpdateTicketItem({
                                       totalAmount,
                                       unitPrice,
                                       discountAmount,
                                       name,
                                       isAfterBorder,
                                       isReturnTicket,
                                       labels,
                                       passengerUid,
                                       isDiscountApplied,
                                       ticketType
                                   }) {
        const existingItem = TICKET_ITEMS_OBJ.find(item => item.unitPrice === unitPrice && item.name === name);

        if (existingItem) {
            existingItem.quantity += 1;
            existingItem.passengerUid.push(...passengerUid);
            existingItem.totalAmount += totalAmount;
        } else {
            TICKET_ITEMS_OBJ.push({
                totalAmount,
                quantity: 1,
                unitPrice,
                discount: discountAmount,
                name,
                isAfterBorder,
                isReturnTicket,
                labels,
                passengerUid: [...passengerUid],
                isDiscountApplied,
                ticketType
            });
        }
    }

    passengers.forEach((passenger) => {
        const passUid = uuidv4();
        let discountName = passenger.discountPCT?.name && passenger?.discountPCT?.name  !== "Osnovna karta" ? ` - ${passenger.discountPCT.name}` : '';

        let isDiscountApplied = false;
        let passengerPrice = state.priceOneWay;
        let returnPrice = state.priceReturnTicket;
        let monthlyPrice = state.priceMonthly;
        let discountAmount = 0;
        let ticketType = null;

        if (directionType === DIRECTION_NAME[2]) {
            return addOrUpdateTicketItem({
                totalAmount: monthlyPrice,
                unitPrice: monthlyPrice,
                discountAmount,
                name: `${state.depStationName} - ${state.arrStationName} - Mesečna karta${discountName}`,
                isAfterBorder: false,
                isReturnTicket: false,
                labels: isTaxFree ? [isTaxFree] : ['A'],
                passengerUid: [passUid],
                isDiscountApplied,
                ticketType
            });
        }

        if (passenger.discountPCT) {
            isDiscountApplied = true;
            ticketType = {id: passenger.discountPCT.ticketTypeId};
            if (directionType === DIRECTION_NAME[0]) {
                const roundPrice = passenger.applyDiscountOneWay && passenger?.applyRoundPrice
                passengerPrice = roundPrice ? Math.ceil(passenger?.discountPCT?.priceOneWay/10)*10 : passenger?.discountPCT?.priceOneWay;
                returnPrice = 0;
                discountAmount = state.priceOneWay - passengerPrice;
            } else {
                if (passenger.discountPCT.priceOneWay < passenger.discountPCT.priceReturnTicket) {
                    const roundPriceReturn = passenger.applyDiscountReturnTicket && passenger?.applyRoundPrice
                    const roundPrice = passenger.applyDiscountOneWay && passenger?.applyRoundPrice
                    passengerPrice = roundPrice ? Math.ceil(passenger?.discountPCT?.priceOneWay/10)*10 : passenger?.discountPCT?.priceOneWay;
                    returnPrice = roundPriceReturn ? Math.ceil(passenger.discountPCT.priceReturnTicket - passengerPrice/10)*10: passenger.discountPCT.priceReturnTicket - passengerPrice;
                    discountAmount = state.priceOneWay - passengerPrice;
                } else {
                    passengerPrice = passenger.discountPCT.priceOneWay / 2;
                    returnPrice = passengerPrice;
                    discountAmount = state.priceReturnTicket - passenger.discountPCT.priceReturnTicket;
                }
            }
        } else {
            returnPrice = returnPrice - passengerPrice
        }

        //TODO ovo samo za stvarne prevoznike ukljuciti
        // if ((state.arrCountryName !== "Srbija" && state.arrCountryCode !== "RS") ||
        //     (state.depCountryName !== "Srbija" && state.depCountryCode !== "RS")) {
        //
        //     // Cena pre granice
        //     const ticketPriceBeforeBorder = formulaForBorderPrice(passengerPrice, state.depBorderDistance, state.arrBorderDistance);
        //     console.log(ticketPriceBeforeBorder, "ticketPriceBeforeBorder")
        //     console.log(state, "state")
        //     const itemBeforeBorder = {
        //         totalAmount: ticketPriceBeforeBorder,
        //         unitPrice: ticketPriceBeforeBorder,
        //         quantity: 1,
        //         name: `${state.depStationName} - ${state.borderName}${discountName}`,
        //         labels: isTaxFree ? [isTaxFree] : ['A'],
        //         isAfterBorder: false,
        //         passengerUid: [passUid],
        //         isDiscountApplied,
        //         discount: 0,
        //         ticketType
        //     };
        //
        //     // Cena posle granice
        //     const ticketPriceAfterBorder = formulaAfterForBorderPrice(passengerPrice, state.depBorderDistance, state.arrBorderDistance);
        //     console.log(ticketPriceAfterBorder, "ticketPriceAfterBorder")
        //     const itemAfterBorder = {
        //         totalAmount: ticketPriceAfterBorder,
        //         unitPrice: ticketPriceAfterBorder,
        //         quantity: 1,
        //         name: `${state.borderName} - ${state.arrStationName}${discountName}`,
        //         labels: isTaxFree ? [isTaxFree] : ['A'],
        //         isAfterBorder: true,
        //         passengerUid: [passUid],
        //         isDiscountApplied,
        //         discount: 0,
        //         ticketType
        //     };
        //
        //     TICKET_ITEMS_OBJ.push(itemBeforeBorder);
        //     TICKET_ITEMS_OBJ.push(itemAfterBorder);
        // }
        // else {
        // Jedan smer
        if (directionType === DIRECTION_NAME[0]) {
            addOrUpdateTicketItem({
                totalAmount: passengerPrice,
                unitPrice: passengerPrice,
                discountAmount,
                name: `${state.depStationName} - ${state.arrStationName} - Jedan smer${discountName}`,
                isAfterBorder: false,
                isReturnTicket: false,
                labels: isTaxFree ? [isTaxFree] : ['A'],
                passengerUid: [passUid],
                isDiscountApplied,
                ticketType
            });
        } else {
            // Jedan smer - povratak
            addOrUpdateTicketItem({
                totalAmount: passengerPrice,
                unitPrice: passengerPrice,
                discountAmount: 0,
                name: `${state.depStationName} - ${state.arrStationName} - Jedan smer${discountName}`,
                isAfterBorder: false,
                isReturnTicket: false,
                labels: isTaxFree ? [isTaxFree] : ['A'],
                passengerUid: [passUid],
                isDiscountApplied,
                ticketType
            });

            // Povratna karta
            addOrUpdateTicketItem({
                totalAmount: returnPrice,
                unitPrice: returnPrice,
                discountAmount,
                name: `${(returnTrip?.arrStationName || state.arrStationName)} - ${(returnTrip?.depStationName || state.depStationName)} - Povratna${discountName}`,
                isAfterBorder: false,
                isReturnTicket: true,
                labels: isTaxFree ? [isTaxFree] : ['A'],
                passengerUid: [passUid],
                isDiscountApplied,
                ticketType
            });
        }
        //  }

    });

    return TICKET_ITEMS_OBJ;
};

// Funkcija za izračunavanje popusta
export const calcDiscount = (price, returnPrice, discountPCT, discountABS, applyRoundPrice, directionType) => {
    let discountedOneWayPrice = price;
    let discountedReturnPrice = returnPrice;
    let discount = 0;

    // Primena procentualnog popusta
    if (discountPCT > 0) {
        if (directionType === DIRECTION_NAME[0]) {
            // Ako je jedan smer, primeni popust samo na cenu za jedan smer
            discount = price * discountPCT / 100;
            discountedOneWayPrice = price - discount;

            if (applyRoundPrice) {
                discountedOneWayPrice = Math.ceil(discountedOneWayPrice / 10) * 10;
            }
        } else {
            // Ako je povratna karta, primeni popust na ukupnu cenu povratne karte
            discountedReturnPrice = returnPrice - (returnPrice * discountPCT / 100);
            discount = returnPrice * discountPCT / 100;

            if (discountedReturnPrice < price) {
                discountedOneWayPrice = discountedReturnPrice;
                discountedReturnPrice = 0;
            } else {
                discountedReturnPrice = discountedReturnPrice - price;
            }

            if (applyRoundPrice) {
                discountedOneWayPrice = Math.ceil(discountedOneWayPrice / 10) * 10;
                discountedReturnPrice = Math.ceil(discountedReturnPrice / 10) * 10;
            }
        }
    }

    // Primena apsolutnog popusta
    if (discountABS > 0) {
        if (directionType === DIRECTION_NAME[0]) {
            // Ako je jedan smer, primeni apsolutni popust samo na cenu za jedan smer
            discount = discountABS;
            discountedOneWayPrice = price - discountABS;
            if (discountedOneWayPrice < 0) {
                discountedOneWayPrice = 0;
            }

            if (applyRoundPrice) {
                discountedOneWayPrice = Math.ceil(discountedOneWayPrice / 10) * 10;
            }
        } else {
            // Ako je povratna karta, primeni apsolutni popust na ukupnu cenu povratne karte
            discount = discountABS;
            discountedOneWayPrice = price - discountABS;
            discountedReturnPrice = returnPrice - discountABS;

            if (discountedOneWayPrice < 0) {
                discountedOneWayPrice = 0;
            }
            if (discountedReturnPrice < 0) {
                discountedReturnPrice = 0;
            }

            if (applyRoundPrice) {
                discountedOneWayPrice = Math.ceil(discountedOneWayPrice / 10) * 10;
                discountedReturnPrice = Math.ceil(discountedReturnPrice / 10) * 10;
            }
        }
    }

    return {
        discountedOneWayPrice,
        discountedReturnPrice,
        discount
    };
};
export const formulaForDiscount = (price, discount) => {
    if (discount > 0) {
        return price * discount / 100
    } else return 0

}
