import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {
    deletePlatformAPI, fetchPlatformsBusynessAPI,
    getAllPlatformsByStationIdAPI,
    insertNewPlatformAPI,
    updatePlatformAPI
} from "../../api/platform";

export const fetchPlatformsBusyness = createAsyncThunk('fetchPlatformsBusyness',
    async ({stationId, platformId, sortOrder}) => {
    return (await fetchPlatformsBusynessAPI(stationId, platformId, sortOrder)).data
})

export const fetchAllPlatformsByStationId = createAsyncThunk('fetchAllPlatformsByStationId', async (stationId) => {
    return (await getAllPlatformsByStationIdAPI(stationId)).data
})

export const insertNewPlatform = createAsyncThunk('insertNewPlatform', async (data) => {
    return (await insertNewPlatformAPI(data)).data
})

export const deletePlatform = createAsyncThunk('deletePlatform', async (data) => {
    return (await deletePlatformAPI(data)).data
})

export const updatePlatform = createAsyncThunk('updatePlatform', async (data) => {
    return (await updatePlatformAPI(data)).data
})


const initialState = {
    platforms: {
        content: [],
        totalElements: 0,
    },
    platformsById: [],
    busyness: {
        content: [],
        totalElements: 0,
    },
    loading: false,
    loadingBusyness: false,
    updating: false,
}

const slice = createSlice({
    name: 'platforms',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllPlatformsByStationId.pending]: state => {
            state.loading = true;
        },
        [fetchAllPlatformsByStationId.fulfilled]: (state, {payload}) => {
            state.platformsById = payload;
            state.loading = false;
        },
        [fetchAllPlatformsByStationId.rejected]: state => {
            state.loading = false;
        },
        [insertNewPlatform.pending]: state => {
            state.loading = true;
        },
        [insertNewPlatform.fulfilled]: (state, {payload}) => {
            const platforms = [...current(state.platformsById)];
            const index = platforms.findIndex(x => x.id === payload.id);
            if (index === -1) {
                platforms.push(payload);
                state.platformsById = platforms
            }
            state.loading = false;
        },
        [insertNewPlatform.rejected]: state => {
            state.loading = false;
        },
        [updatePlatform.pending]: state => {
            state.loading = true;
        },
        [updatePlatform.fulfilled]: (state, {payload}) => {
            const platforms = [...current(state.platformsById)];
            const index = platforms.findIndex(x => x.id === payload.id);
            if (index !== -1) {
                platforms[index] = payload;
                state.platformsById = platforms
            }
            state.loading = false;
        },
        [updatePlatform.rejected]: state => {
            state.loading = false;
        },
        [deletePlatform.pending]: state => {
            state.loading = true;
        },
        [deletePlatform.fulfilled]: (state, {meta}) => {
            const platforms = [...current(state.platformsById)];
            const index = platforms.findIndex(x => x.id === meta.arg);
            platforms.splice(index, 1)
            state.platformsById = platforms
            state.loading = false;
        },
        [deletePlatform.rejected]: state => {
            state.loading = false;
        },
        [fetchPlatformsBusyness.pending]: state => {
            state.loadingBusyness = true;
        },
        [fetchPlatformsBusyness.fulfilled]: (state, {payload}) => {
            state.busyness = payload
            state.loadingBusyness = false;
        },
        [fetchPlatformsBusyness.rejected]: state => {
            state.loadingBusyness = false;
        },
    }
});

export default slice.reducer

