import PropTypes from "prop-types";
// @mui
import {Autocomplete, Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from "../../components/Iconify";
import {DatePicker} from "@mui/x-date-pickers";
import {CalendarIconSvg} from "../../constants/icons";
// components


DepartureFromSpotToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterLineName: PropTypes.string,
    filterOperatorName: PropTypes.string,
    filterDate: PropTypes.string,
    onResetFilter: PropTypes.func,
    onFilterOperatorName: PropTypes.func,
    onFilterLineName: PropTypes.func,
    onFilterDate: PropTypes.func,
};

export default function DepartureFromSpotToolbar({
                                                     setEndStation,
                                                     isIndividual,
                                                     homeArrStations,
                                                     operator,
                                                     isFiltered,
                                                     filterLineName,
                                                     filterOperatorName,
                                                     filterDate,
                                                     onFilterLineName,
                                                     onFilterDate,
                                                     onFilterOperatorName,
                                                     onResetFilter,
                                                 }) {

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            {!isIndividual &&
                <Autocomplete
                    fullWidth
                    name="endStation"
                    onChange={(e, data) => {
                        setEndStation(data?.id)
                    }}
                    options={homeArrStations}
                    getOptionLabel={(option) => {
                        if (option.stationName) {
                            return option.stationName;
                        }
                        return option;
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            label={"Izaberite stanicu"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    }
                />
            }

            <TextField
                size={"small"}
                fullWidth
                value={filterLineName}
                onChange={onFilterLineName}
                placeholder="Naziv linije..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {
                !operator && <TextField
                    size={"small"}
                    fullWidth
                    value={filterOperatorName}
                    onChange={onFilterOperatorName}
                    placeholder="Naziv prevoznika..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                            </InputAdornment>
                        ),
                    }}
                />
            }

            <DatePicker
                disablePast={true}
                sx={{width: 550}}
                label="Datum"
                value={new Date(filterDate) || null}
                onChange={onFilterDate}
                slotProps={{textField: {size: 'small'}}}
                slots={{openPickerIcon: CalendarIconSvg}}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        {...params}
                    />
                )}
            />


            {
                isFiltered && (
                    <Button
                        color="error"
                        sx={{flexShrink: 0}}
                        onClick={onResetFilter}
                        startIcon={<Iconify icon="eva:trash-2-outline"/>}
                    >
                        Očisti
                    </Button>
                )
            }
        </Stack>
    )
}
