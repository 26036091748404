import {getDataForPrintingTicket} from "../../helper";

let fontSize = "0.7rem";

const style = `
  @media print{ 
    @page{
      size: portrait;  
      margin: 0px !important;
      padding: 0;
      width: 100%,
    }
    body {
      margin-bottom: 20mm; 
    } 
  }
`;

export default function CouponSlip(logo, isSilentPrint = false, printerSize, ticket) {
    console.debug("CouponSlip", {logo, isSilentPrint, printerSize, ticket})
    const {
        ticketId,
        operator,
        departureStationName,
        arrivalStationName,
        ticketType,
        journeyDate,
        departureTime,
        price,
        issuedBy,
        passengerDetails,
        passengerDocumentNumber
    } = getDataForPrintingTicket(ticket)

    let width = 360;
    fontSize = isSilentPrint ? "19px" : fontSize;
    if (printerSize) {
        if (printerSize == "80") {
            width = 560;
            fontSize = "29px"
        }
    }

    return (
        <html lang="sr">
        <head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <style type="text/css">{style}</style>
            <title>Coupon</title>
        </head>
        <body
            id="forPrint"
            style={{
                fontFamily: "Calibri",
                margin: 0,
                width: "100%",
                maxWidth: isSilentPrint ? "100%" : "80mm",
                justifyContent: "center",
                display: "inline-block",
                height: "auto",
                wordBreak: "break-word",
                fontSize: fontSize
            }}>
        <table style={{
            width: isSilentPrint ? width : "auto",
            margin: 0
        }}>
            <tbody style={{marginBottom: "20px"}}>
            {logo ?
                <tr>
                    <td style={{textAlign: "center"}} colSpan={4}>
                        <img alt="logo" src={logo} width={isSilentPrint ? width - 100 : "50%"}
                             height="auto"/>
                    </td>
                </tr> : <></>
            }
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    {issuedBy}
                </td>
            </tr>
            <tr>
                <td style={{textAlign: "center", fontSize: fontSize}} colSpan={4}>
                    KUPON ZA PREVOZNIKA
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Broj karte:
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {ticketId}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Prevoznik
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {operator}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Polazište
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {departureStationName}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Odredište
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {arrivalStationName}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Vrsta karte
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {ticketType}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Datum putovanja
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {journeyDate}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Vreme polaska
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {departureTime}
                </td>
            </tr>
            <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    Cena
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {price}
                </td>
            </tr>
            {ticket.ticketType && ticket.ticketType.id !== 1 && ticket.ticketType.operator === null && <tr style={{justifyContent: "space-between"}}>
                <td style={{fontSize: fontSize}} colSpan={2}>
                    {passengerDetails}
                </td>
                <td style={{fontSize: fontSize, fontWeight: 700, textAlign: "right"}} colSpan={2}>
                    {passengerDocumentNumber}
                </td>
            </tr>}
            </tbody>
        </table>
        </body>
        </html>
    );
}
