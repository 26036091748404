import React, {createContext, useEffect, useState} from 'react';
import {child, off, onChildAdded, onChildChanged, ref, update} from "firebase/database"; // Import update
import {getIniLoggedUser, getSessionOperator} from "../../helper/session";
import {AUTH, dbRef, RDB} from "../../auth/FirebaseContext";

export const FirebaseContext = createContext();

export const FirebaseProvider = ({children}) => {
    const [newMessage, setNewMessage] = useState(null);
    const [messages, setMessages] = useState([]);
    const primaryUser = getIniLoggedUser();
    const operator =  getSessionOperator();
    useEffect(() => {
        const fetchMessages = () => {
            if (primaryUser?.uid) {
                const messagesRef = ref(RDB, `/users/${primaryUser.uid}/private/messages/kiosk`);

                onChildAdded(messagesRef, (snapshot) => {
                    const messageData = snapshot.val();
                    const messageId = snapshot.key;
                    setMessages(prevMessages => [
                        ...prevMessages,
                        {id: messageId, ...messageData}
                    ]);
                });

                onChildChanged(messagesRef, (snapshot) => {
                    const messageData = snapshot.val();
                    const messageId = snapshot.key;
                    setMessages(prevMessages =>
                        prevMessages.map(msg => msg.id === messageId ? {id: messageId, ...messageData} : msg)
                    );
                });

                return () => {
                    off(messagesRef);
                };
            }
        };

        fetchMessages();
    }, [primaryUser?.uid]);

    const markMessageAsRead = async (messageId) => {
        if (primaryUser?.uid) {

            const messageRef = ref(RDB, `users/${primaryUser.uid}/private/messages/kiosk/${messageId}`);
            await update(messageRef, { readBy: operator?.username });
            setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
        }
    };

    const unreadMessages = messages.filter(msg => !msg.readBy);
    const latestUnreadMessage = unreadMessages[unreadMessages.length - 1];

    return (
        <FirebaseContext.Provider value={{newMessage: latestUnreadMessage, markMessageAsRead}}>
            {children}
        </FirebaseContext.Provider>
    );
};
