import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import React, {useLayoutEffect, useState} from "react";
import {allSeats} from "../../api/trip";
import moment from "moment";
import {useSnackbar} from "notistack";
import SeatsLayout from "./SeatsLayout";

export default function SeatsDialog({
                                        setValue,
                                        getValues,
                                        passIndex,
                                        open,
                                        onClose,
                                        onConfirm,
                                        tripId,
                                        departureTime,
                                        arrStationId,
                                        depStationId,
                                        passengers,
                                        directionType
                                    }) {
    const {enqueueSnackbar} = useSnackbar();
    const [freeSeats, setFreeSeats] = useState([])
    const [loading, setLoading] = useState(true)

    useLayoutEffect(() => {
        findFreeSeats()
    }, [])

    const findFreeSeats = () => {
        allSeats({
            id: tripId,
            date: moment(departureTime).format("yyyy-MM-DD"),
            depId: depStationId,
            arrId: arrStationId
        }).then(({data}) => {
            setFreeSeats(data)
        }).catch(() => {
            enqueueSnackbar("Nema slobodnih mesta", {variant: "info"})
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCancel = () => {
        if (onConfirm) {
            onConfirm(null)
        }
        onClose();
    }

    const handleSubmit = (seat) => {
        if (onConfirm) {
            onConfirm(seat)
        }
        onClose();
    }

    return (
        <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
            <DialogContent>
                <SeatsLayout
                    isReservation={false}
                    setValue={setValue}
                    getValues={getValues}
                    passIndex={passIndex}
                    arrStationId={arrStationId}
                    depStationId={depStationId}
                    directionType={directionType}
                    passengers={passengers}
                    seats={freeSeats}
                    wrapperSx={{mb: 2}}
                    onSelectSeat={handleSubmit}
                    loading={loading}/>
            </DialogContent>
            <DialogActions>
                {/*<Button variant="outlined" color="inherit" onClick={handleCancel}>*/}
                {/*    Poništi izbor*/}
                {/*</Button>*/}
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Otkaži
                </Button>
            </DialogActions>
        </Dialog>
    );
}
